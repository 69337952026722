import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import localforage from "localforage";

const routes = [
    { path: "/", redirect: "/login" },
    {
        path: "/login",
        component: () => import("@/views/LoginView.vue"),
        meta: { requiresAuth: false },
    },
    {
        path: "/home",
        component: () => import("@/views/HomeView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/forms",
        component: () => import("@/views/FormsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/settings",
        component: () => import("@/views/SettingsView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/users",
        component: () => import("@/views/UserManagement.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/analyze",
        component: () => import("@/views/AnalyzeView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/submission/:token",
        component: () => import("@/views/SubmissionView.vue"),
        props: true,
        meta: { requiresAuth: false },
    },
    {
        path: "/documents/:file",
        name: "externalDocuments",
        meta: { requiresAuth: true, externalLink: true },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: '/home',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        const token = await localforage.getItem("token");

        axios.defaults.baseURL = process.env.VUE_APP_API_URL;

        try {
            const response = await axios.post("/validate", { token });

            if('userId' in response.data && response.data.userId !== ''){
                if('externalLink' in to.meta && to.meta.externalLink === true){
                    //window.open('/documents/' + to.params.file, '_blank');
                    next(false);

                } else {
                    next();
                }

            } else {
                await localforage.removeItem("token");
                next('/login');                
            }

        } catch (error) {
            await localforage.removeItem("token");
            next('/login');
        }

    } else {
        next();
    }
});

export default router;
