<template>
    <div>
      <header>
      </header>
  
      <main>
        <router-view></router-view>
      </main>
  
      <footer>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'andNowRun',
  }
  </script>

  <style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@300&display=swap');

  body {
    padding: 0;
    margin: 0;
   }

   body {
    font-family: 'Roboto Condensed';
    background-color: #222;
   }

  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 48
  }

  span.material-symbols-outlined {
    margin-right: 10px;
  }


  </style>